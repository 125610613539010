import {request} from "../../request";
//租赁房屋、待上报数量
export function submissionInfo(data){
  return request({
    url:'home/general/submission',
    method:'get',
    params:data
  })
}
//获取普通用户租赁的所有房屋
export function rentRooms(data){
  return request({
    url:'home/general/rentRooms',
    method:'get',
    params:data
  })
}
//某房屋安全情况趋势
export function roomsTrend(data){
  return request({
    url:'home/risk/trend',
    method:'get',
    params:data
  })
}