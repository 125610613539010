<template>
    <div class="out_container">
        <v-row>
            <v-col cols="12" md="6">
                <v-card height="380px" elevation="1">
                    <v-card-title class="card-title text-subtitle-1">概览</v-card-title>
                    <v-divider></v-divider>
                    <div class="card_in">
                        <div class="card_l d-flex flex-column align-center justify-sm-center">
                            <el-progress type="circle" :percentage="percentage" color="#FF4949" :stroke-width="18"
                                         :format="format1"></el-progress>
                            <div class="text-subtitle-1  mt-3">待上报数量</div>
                        </div>
                        <div class="card_r d-flex flex-column align-center justify-sm-center">
                            <el-progress type="circle" :percentage="100" :stroke-width="18"
                                         :format="format2"></el-progress>
                            <div class="text-subtitle-1  mt-3">租赁数量</div>
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="card_notice" height="380px" elevation="1">
                    <v-card-title class="card-title text-subtitle-1">公告信息</v-card-title>
                    <v-divider></v-divider>
                    <div class="text-subtitle-1 mt-4 ml-4">{{post.content}}</div>
                    <div class="time">{{post.date}}</div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-card height="480px" elevation="1">
                    <v-card-title class="card-title text-subtitle-1">安全情况走势图</v-card-title>
                    <v-divider></v-divider>
                    <!--筛选-->
                    <div class="d-flex flex-row align-center ma-3">
                        <div class="text-lg-subtitle-2 ml-1 mr-2">租赁房屋</div>
                        <el-select @change="handleChange" size="mini" v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.roomId"
                                    :value="item.roomId"
                                    :label="item.address"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="d-flex justify-center align-center">
                        <div id="chart1" style="width: 100%;height:300px"></div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import {submissionInfo, rentRooms, roomsTrend} from "../../network/Details/general_user/userHome";
    import {getPost} from "../../network/Details/home";

    var util = require('assets/js/util.js');
    export default {
        name: "UserHome",
        created() {
            //获取公告
            getPost({
                userId: this.userId
            }).then(res => {
                if (res.status === 200) {
                    this.post.content = res.data.content;
                    this.post.date = res.data.postTime;
                }
            })
            //获取名下所有房屋信息
            rentRooms({
                userId: this.userId
            }).then(res => {
                if (res.status === 200) {
                    this.value = res.data[0].roomId;
                    this.options = res.data;
                    this.$nextTick(() => {
                        this.showChart1();
                    })
                }
            });
            submissionInfo({
                userId: this.userId
            }).then(res => {
                this.leaseHouse = res.data.sum;
                this.notUpHouse = res.data.notReported;
                this.percentage = this.notUpHouse / this.leaseHouse * 100;
            })
            this.date = util.formatTime(new Date());
        },
        mounted() {
            let _this = this;
            window.onresize = function () {
                if (_this.myChart) {
                    _this.myChart.resize();
                }
            }
        },
        methods: {
            handleChange() {
                this.showChart1();
            },
            format1() {
                return `${this.notUpHouse}个`;
            },
            format2() {
                return `${this.leaseHouse}个`;
            },
            //图表
            showChart1() {
                let ranks, scores;
                // 基于准备好的dom，初始化echarts实例
                roomsTrend({
                    roomId: this.value
                }).then(res => {
                    if (res.status === 200) {
                        ranks = res.data.ranks;
                        scores = res.data.scores;
                        let myChart = this.myChart;
                        myChart = this.$echarts.init(document.getElementById('chart1'));
                        let option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'cross',
                                    crossStyle: {
                                        color: '#999'
                                    }
                                }
                            },
                            toolbox: {},
                            legend: {
                                top: 'bottom',
                                data: ['安全等级评分', '安全风险评估']
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                                    axisPointer: {
                                        type: 'shadow'
                                    }
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value',
                                    name: '安全等级评分',
                                    min: 0,
                                    max: 100,
                                    interval: 50,
                                    axisLabel: {
                                        formatter: '{value} 分'
                                    }
                                },
                                {
                                    type: 'value',
                                    name: '安全风险评估',
                                    min: 0,
                                    max: 100,
                                    interval: 20,
                                    axisLabel: {
                                        formatter: '{value} 分'
                                    }
                                }
                            ],
                            series: [
                                {
                                    name: '安全等级评分',
                                    type: 'bar',
                                    data: ranks
                                },
                                {
                                    name: '安全风险评估',
                                    type: 'line',
                                    yAxisIndex: 1,
                                    data: scores
                                }
                            ]
                        };
                        myChart.setOption(option);
                        window.addEventListener('resize', function () {
                            if (myChart) {
                                myChart.resize();
                            }
                        });
                    }
                });
            }
        },
        data() {
            return {
                post: {
                    content: "暂无公告",
                    date: ''
                },
                //用户id
                userId: window.localStorage.getItem("userId"),
                myChart: null,
                options: [],
                value: [],
                notUpHouse: 0,
                leaseHouse: 0,
                percentage: 0
            }
        }
    }
</script>

<style lang="less" scoped>
    .out_container {
        margin-top: 1%;
        width: 98%;
        margin-left: 1%;
    }

    .card-title {
        height: 50px;
    }

    .card_in {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-content: center;
        height: 300px;

        .card_l {
        }

        .card_r {

        }
    }

    .card_notice {
        position: relative;

        .time {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
    }
</style>